import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useObservable, useSubscription } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
import { CUSTOM_FIELDS_TYPES } from 'src/features2/common/customField/di/types';
import { ICustomFieldUseCase } from 'src/features2/common/customField/domain/abstractions/ICustomFieldUseCase';
import { v4 } from 'uuid';
import { isMobileOnly } from 'react-device-detect';

const useManageFieldsViewModel = () => {
  const { t } = useTranslation('customFields');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [newFieldName, setNewFieldName] = useState('');
  const [customFieldsList, setCustomFieldsList] = useState<CustomFieldEntity[]>([]);
  const customFieldUseCase = useInjection<ICustomFieldUseCase>(
    CUSTOM_FIELDS_TYPES.CustomFieldUseCase
  );
  const customFieldsObservable = useObservable(() => customFieldUseCase.getAll(), []);
  const { enqueueSnackbar } = useSnackbar();

  useSubscription(customFieldsObservable, (fields) => {
    setCustomFieldsList(fields);
  });

  const onAddField: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const onCloseAddPopup = () => {
    setAnchorEl(null);

    if (newFieldName) {
      setNewFieldName('');
    }
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setNewFieldName(value);
  };

  const onCreateField = () => {
    customFieldUseCase.createField({ uuid: v4(), name: newFieldName }).subscribe({
      complete: () => {
        onCloseAddPopup();
        enqueueSnackbar({
          message: t('fields_management.new_field_dialog.success_toast'),
          variant: MessageType.Success,
        });
      },
    });
  };

  useEffect(() => {
    if (isMobileOnly) {
      customFieldUseCase.fetchAndSaveCustomFields().subscribe();
    }
  }, [isMobileOnly]);

  return {
    anchorEl,
    newFieldName,
    customFieldsList,
    onAddField,
    onCloseAddPopup,
    onChange,
    onCreateField,
  };
};

export default useManageFieldsViewModel;
