import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import { TeamMember } from '../../../../api/workspacesAPI';
import Avatar from '../../../../components/UI/Avatar';
import DownArrowIcon from '../../../../../public/images/icons/down-arrow.svg';

import styles from './AssignToSelect.module.css';
import TeammembersMenu from './TeammembersMenu';

type AssignToSelectProps = {
  name?: string;
  value: TeamMember['email'];
  options: TeamMember[];
  onSelect: (value: TeamMember['email']) => void;
  disabled?: boolean;
  className?: string;
  buttonClassName?: string;
};

export default function AssignToSelect(props: AssignToSelectProps) {
  const { t } = useTranslation();

  const { options, value, onSelect, disabled = false, className, buttonClassName } = props;

  const anchorRef = useRef<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const selectedTeamMember = options.find((teamMember) => teamMember.email === value);

  return (
    <div className={className}>
      <button
        className={clsx(styles.button, buttonClassName)}
        onClick={() => {
          setAnchorEl(anchorRef.current);
        }}
        ref={anchorRef}
        disabled={disabled}
      >
        {selectedTeamMember ? (
          <>
            <Avatar
              size={14}
              className={styles.buttonAvatar}
              thumbnail={selectedTeamMember.thumbnail}
              name={selectedTeamMember.fullName}
            />
            <span className={styles.buttonText}>{selectedTeamMember.fullName}</span>
          </>
        ) : (
          <span className={styles.buttonText}>{t('common:collaboration.unassigned')}</span>
        )}
        {!disabled && (
          <DownArrowIcon className={clsx(styles.buttonIconArrow, !!anchorEl && styles.menuOpen)} />
        )}
      </button>
      <TeammembersMenu
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={anchorEl}
        options={options}
        value={value}
        onClose={() => setAnchorEl(null)}
        onSelect={onSelect}
      />
    </div>
  );
}
