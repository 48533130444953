import { v4 as uuidv4 } from 'uuid';

import { ItemModel } from 'src/api/boardAPI';

export const createBoardContactItem = (contactUuid: string, columnUuid: string): ItemModel => {
  const now = Math.floor(Date.now() / 1000);

  return {
    type: 'contact',
    typeUuid: contactUuid,
    uuid: uuidv4(),
    columnUuid,
    createdAt: now,
    updatedAt: now,
  };
};
