import React from 'react';
import PageHeader from '../../../common/components/PageHeader';
import { useTranslation } from 'next-i18next';
import { Popover, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import styles from './styles.module.scss';
import ButtonPrimary from 'components/UI/Buttons/ButtonPrimary';
import PlusIcon from '../../../../../../public/images/icons/plus-rounded-icon.svg';
import InputBase from 'components/UI/Inputs/InputBase';
import BaseButton from 'components/UI/Buttons/BaseButton';
import useManageFieldsViewModel from './ManageFieldsViewModel';
import CustomFieldActions from './components/CustomFieldActions';

const ManageFields: React.FC = () => {
  const { t } = useTranslation('customFields');
  const {
    anchorEl,
    newFieldName,
    customFieldsList,
    onAddField,
    onCloseAddPopup,
    onChange,
    onCreateField,
  } = useManageFieldsViewModel();

  const columnTitles = [
    { name: t('table.field_name_column'), width: 250 },
    { name: t('table.field_type_column'), width: 100 },
    { name: '', width: 100 },
  ];
  const systemColumns = [
    t('fields.mobile.title', { ns: 'contact-details' }),
    t('Email_field', { ns: 'duplicates' }),
    t('fields.web.title', { ns: 'contact-details' }),
    t('fields.address.title', { ns: 'contact-details' }),
    t('fields.event.title', { ns: 'contact-details' }),
  ];

  return (
    <div>
      <div className={styles.HeaderWrapper}>
        <PageHeader heading={t('navigation.tabs.fieldsManagement', { ns: 'settings' })} noBorder />
        <ButtonPrimary
          onClick={onAddField}
          text={t('fields_management.add_button')}
          icon={<PlusIcon />}
        />
        <Popover
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={onCloseAddPopup}
          classes={{ paper: styles.AddPopup }}
          anchorOrigin={{
            vertical: 40,
            horizontal: 0,
          }}
        >
          <InputBase
            value={newFieldName}
            onChange={onChange}
            placeholder={t('fields_management.new_field_dialog.placeholder')}
            className={styles.Input}
          />
          <div className={styles.ButtonWrapper}>
            <BaseButton variant="text" color="secondary" onClick={onCloseAddPopup}>
              {t('Cancel_action', { ns: 'duplicates' })}
            </BaseButton>
            <BaseButton onClick={onCreateField} disabled={!newFieldName}>
              {t('fields_management.new_field_dialog.add_button')}
            </BaseButton>
          </div>
        </Popover>
      </div>
      <Table className={styles.Table}>
        <TableHead>
          <TableRow>
            {columnTitles.map((title, index) => (
              <TableCell style={{ width: title.width }} key={index}>
                {title.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {systemColumns.map((column, index) => {
            return (
              <TableRow key={index}>
                <TableCell style={{ width: 190 }}>{column}</TableCell>
                <TableCell style={{ width: 190 }}>
                  <div className={styles.Badge}>{t('table.field_type_system')}</div>
                </TableCell>
              </TableRow>
            );
          })}
          {customFieldsList.map((field) => {
            return (
              <TableRow key={field.uuid}>
                <TableCell style={{ width: 190 }}>{field.name}</TableCell>
                <TableCell style={{ width: 190 }}>
                  <div className={styles.Badge}>{t('table.field_type_custom')}</div>
                </TableCell>
                <TableCell>
                  <CustomFieldActions field={field} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManageFields;
