import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../../../app/store/store';
import { useAppDispatch, useAppStore } from '../../../app/hooks';

import CallEntity from '../../../db/entities/call/CallEntity';
import { selectBoard, actions } from '../../../slices/boardSlice';
import {
  selectCalls,
  hideCallsBatch,
  findRelatedContactByNormalizedPhone,
} from '../../../slices/callsSlice';
import {
  selectContactsArray,
  createContact,
  patchContactOptimistic,
  selectContactsMap,
} from '../../../slices/contactsSlice';
import { selectUserProfile } from '../../../slices/userSlice';
import { selectCurrentUserMemberData } from '../../../slices/workspacesSlice';
import {
  makeSelectProposalsByContact,
  actions as proposalsActions,
} from '../../../slices/proposalsSlice';

import { ItemModel } from '../../../api/boardAPI';
import useDuplicateContact from '../../contacts/hooks/useDuplicateContact';
import Contact from '../../../../types/contact';

export default function useCreateItemByCall() {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const duplicateContact = useDuplicateContact();

  // this function will find related contact or create / duplicate existed if necessary
  const getRelatedContactId = (call: CallEntity): Contact['id'] => {
    const state = store.getState() as AppState;

    const { email } = selectUserProfile(state);
    const contacts = selectContactsArray(state);

    const relatedContact = findRelatedContactByNormalizedPhone(call.normalizedPhone, contacts);

    if (!relatedContact) {
      const id = uuidv4();
      dispatch(
        createContact({
          uuid: id,
          workspace_id: state.workspaces.current,
          assigned_to: email,
          name: call.callerInfo.name,
          job_title: call.callerInfo.jobTitle,
          is_spammer: !!call.callerInfo.spam,
          thumbnail: call.callerInfo.thumbnail,
          phones: [
            {
              type: 'mobile',
              phone: call.phoneNumber,
              custom_label: null,
              normalized_phone: call.normalizedPhone,
            },
          ],
        })
      )
        .unwrap()
        .catch(() => {
          dispatch(actions.setSyncFailed());
        });

      return id;
    } else {
      const { email } = selectCurrentUserMemberData(store.getState());

      if (relatedContact.workspace_id === null) {
        const id = uuidv4();

        duplicateContact(relatedContact.uuid, { uuid: id }).catch(() => {
          dispatch(actions.setSyncFailed());
        });

        return id;
      } else {
        if (relatedContact.assigned_to !== email) {
          const data = {
            assigned_to: email,
            updated_at: Math.floor(Date.now() / 1000),
          };

          dispatch(patchContactOptimistic({ contactId: relatedContact.uuid, data }));
        }

        return relatedContact.uuid;
      }
    }
  };

  return async ({
    callId,
    destColId,
    destIndex,
  }: {
    callId: string;
    destColId: string;
    destIndex: number;
  }) => {
    const state = store.getState() as AppState;
    const board = selectBoard(state);
    const calls = selectCalls(state);

    const call = calls.find((feedItem) => feedItem.id === callId);

    const destColumn = board.columns.find((colItem) => colItem.id === destColId);

    const relatedContactId = getRelatedContactId(call);

    if (relatedContactId) {
      const selectProposalsByContact = makeSelectProposalsByContact();
      const proposalIds = selectProposalsByContact(store.getState(), relatedContactId)
        .filter(({ visible }) => visible)
        .map(({ uuid }) => uuid);

      if (proposalIds.length > 0) {
        dispatch(proposalsActions.hideProposals(proposalIds));
      }
    }

    const item: ItemModel = {
      uuid: uuidv4(),
      typeUuid: relatedContactId,
      type: 'contact',
      columnUuid: destColId,
      createdAt: Math.floor(Date.now() / 1000),
      updatedAt: Math.floor(Date.now() / 1000),
    };

    const sameItem = destColumn.items.find(({ typeUuid }) => typeUuid === item.typeUuid);

    if (sameItem) {
      dispatch(
        actions.changeItemOrder({
          columnUuid: destColId,
          itemUuid: sameItem.uuid,
          index: destIndex,
        })
      );
    } else {
      dispatch(actions.addItem({ columnUuid: destColId, item }));
      dispatch(
        actions.changeItemOrder({
          columnUuid: destColId,
          itemUuid: item.uuid,
          index: destIndex,
        })
      );
    }
    const contactsMap = selectContactsMap(store.getState() as AppState);
    const contact = contactsMap[relatedContactId];
    const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
    dispatch(hideCallsBatch(contactPhones));
  };
}
