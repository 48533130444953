import React, { useState } from 'react';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
import IconEdit from '../../../../../../../../public/images/icons/edit.svg';
import IconDelete from '../../../../../../../../public/images/icons/delete.svg';
import styles from './styles.module.scss';
import { useInjection } from 'src/app/ioc/ioc.react';
import { ICustomFieldUseCase } from 'src/features2/common/customField/domain/abstractions/ICustomFieldUseCase';
import { CUSTOM_FIELDS_TYPES } from 'src/features2/common/customField/di/types';
import DeleteDialog from 'components/UI/Dialogs/DeleteDialog';
import { useTranslation } from 'next-i18next';
import { Dialog, DialogContent, DialogTitle, Popover } from '@material-ui/core';
import InputBase from 'components/UI/Inputs/InputBase';
import BaseButton from 'components/UI/Buttons/BaseButton';

interface Props {
  field: CustomFieldEntity;
}

const CustomFieldActions: React.FC<Props> = ({ field }) => {
  const { t } = useTranslation('customFields');
  const customFieldUseCase = useInjection<ICustomFieldUseCase>(
    CUSTOM_FIELDS_TYPES.CustomFieldUseCase
  );
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isRenameDialogVisible, setIsRenameDialogVisible] = useState(false);
  const [fieldName, setFieldName] = useState(field.name);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);

  const onDelete = () => {
    onDeleteClose();
    customFieldUseCase.deleteField(field.uuid).subscribe();
  };

  const onDeleteDialog = () => {
    setIsDeleteDialogVisible(true);

    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const onDeleteClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const onRenameDialog = () => {
    setIsRenameDialogVisible(true);

    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const onRenameDialogClose = () => {
    setIsRenameDialogVisible(false);

    if (fieldName !== field.name) {
      setFieldName(field.name);
    }
  };

  const onRename = () => {
    customFieldUseCase.createField({ uuid: field.uuid, name: fieldName }).subscribe({
      complete: () => {
        setIsRenameDialogVisible(false);
      },
    });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setFieldName(value);
  };

  const onMobileMenu: React.MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const onMobileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Desktop}>
        <IconEdit onClick={onRenameDialog} />
        <IconDelete onClick={onDeleteDialog} />
      </div>
      <div className={styles.Mobile}>
        <div className={styles.DotsButton} onClick={onMobileMenu} role="button" tabIndex={0}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onMobileMenuClose}
        classes={{ paper: styles.MobileMenu }}
      >
        <div className={styles.MobileButton} onClick={onRenameDialog}>
          <IconEdit />
          <div>Edit</div>
        </div>
        <div className={styles.MobileButton} onClick={onDeleteDialog}>
          <IconDelete />
          <div>Delete</div>
        </div>
      </Popover>
      <DeleteDialog
        open={isDeleteDialogVisible}
        onSuccess={onDelete}
        onClose={onDeleteClose}
        title={t('fields_management.delete_dialog.title', { name: field.name })}
        description={t('fields_management.delete_dialog.subtitle')}
        descriptionClassName={styles.DeleteDialogDescription}
      />
      <Dialog
        classes={{ paper: styles.container }}
        open={isRenameDialogVisible}
        onClose={onRenameDialogClose}
      >
        <DialogTitle className={styles.title}>
          {t('fields_management.rename_dialog.title')}
        </DialogTitle>
        <DialogContent className={styles.DialogContent}>
          <InputBase className={styles.Input} value={fieldName} onChange={onChange} />
          <div className={styles.Buttons}>
            <BaseButton variant="text" color="secondary" onClick={onRenameDialogClose}>
              {t('Cancel_action', { ns: 'duplicates' })}
            </BaseButton>
            <BaseButton onClick={onRename}>{t('fields_management.rename_dialog.ok')}</BaseButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomFieldActions;
