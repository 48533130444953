import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

import CheckIcon from 'icons/check.svg';
import styles from '../styles.module.scss';
import { trackEvent } from 'src/analytics/amplitude';

interface IProps {
  color: string;
  title: string;
  action: string;
  eventType: string;
  onClick: () => void;
  isSelected: boolean;
  className?: string;
}

const MoveToItem: React.FC<IProps> = ({
  color,
  title,
  onClick,
  isSelected,
  className,
  action,
  eventType,
}) => {
  const handleClick = () => {
    trackEvent(eventType, { action });
    onClick();
  };
  return (
    <MenuItem onClick={handleClick} className={clsx(styles.MenuItem, className)}>
      <div className={styles.label}>
        <div className={styles.labelBackground} style={{ backgroundColor: color }}></div>
        <span className={styles.labelText} style={{ color }}>
          {title}
        </span>
      </div>
      {isSelected && <CheckIcon />}
    </MenuItem>
  );
};

export default MoveToItem;
