import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import Menu from '@material-ui/core/Menu';
import { v4 as uuidv4 } from 'uuid';

import useDuplicateContact from '../../../features/contacts/hooks/useDuplicateContact';
import { useAppDispatch, useAppSelector, useAppStore } from '../../../app/hooks';
import { selectBoard, actions } from '../../../slices/boardSlice';
import { selectContactsMap, patchContactOptimistic } from '../../../slices/contactsSlice';
import {
  makeSelectProposalsByContact,
  actions as proposalsActions,
} from '../../../slices/proposalsSlice';

import styles from './styles.module.scss';
import MoveToItem from './components/MoveToItem';
import ArchiveToast from './components/ArchiveToast';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import useCreateItemByCall from '../../../features/calls/Board/useCreateItemByCall';
import { hideCallsBatch } from 'slices/callsSlice';

interface IProps {
  anchorEl: HTMLElement;
  columnUuid?: string;
  contactUuid?: string;
  itemUuid?: string;
  proposalSelected: boolean;
  onSendProposal: () => void;
  action: string;
  anchorOrigin?: {
    vertical: number | 'bottom' | 'top' | 'center';
    horizontal: number | 'left' | 'center' | 'right';
  };
  onClose?: () => void;
}

const MoveToModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    columnUuid,
    itemUuid,
    anchorEl,
    onClose,
    contactUuid,
    anchorOrigin,
    proposalSelected,
    action,
    onSendProposal,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const board = useAppSelector(selectBoard);
  const open = Boolean(anchorEl);

  const contactsMap = useAppSelector(selectContactsMap);
  const contact = contactsMap[contactUuid];
  const createItemByCall = useCreateItemByCall();
  const duplicateContact = useDuplicateContact();
  const moveItem = async (dest: string) => {
    withErrorSnackbar({
      onTry: async () => {
        if (contact) {
          const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
          dispatch(hideCallsBatch(contactPhones)).unwrap();
        }

        if (itemUuid) {
          if (columnUuid) {
            dispatch(
              actions.moveItem({
                sourceColumnUuid: columnUuid,
                destColumnUuid: dest,
                itemUuid,
              })
            );

            dispatch(
              actions.changeItemOrder({
                columnUuid: dest,
                itemUuid,
                index: 0,
              })
            );
          } else {
            createItemByCall({
              callId: itemUuid,
              destColId: dest,
              destIndex: 0,
            });
          }
        } else {
          let currentContact = contact;
          if (currentContact.workspace_id === null) {
            const id = await duplicateContact(currentContact.uuid);
            if (currentContact.is_archived) {
              await dispatch(
                patchContactOptimistic({
                  contactId: currentContact.uuid,
                  data: { is_archived: false, updated_at: Math.floor(Date.now() / 1000) },
                })
              ).unwrap();
            }
            currentContact = selectContactsMap(store.getState())[id];
          }

          if (currentContact.is_archived) {
            await dispatch(
              patchContactOptimistic({
                contactId: currentContact.uuid,
                data: { is_archived: false, updated_at: Math.floor(Date.now() / 1000) },
              })
            ).unwrap();
          }

          if (currentContact) {
            const selectProposalsByContact = makeSelectProposalsByContact();
            const proposalIds = selectProposalsByContact(store.getState(), currentContact.uuid)
              .filter(({ visible }) => visible)
              .map(({ uuid }) => uuid);

            if (proposalIds.length > 0) {
              dispatch(proposalsActions.hideProposals(proposalIds));
            }
          }

          dispatch(
            actions.addItem({
              columnUuid: dest,
              item: {
                type: 'contact',
                typeUuid: currentContact.uuid,
                uuid: uuidv4(),
                columnUuid: dest,
                createdAt: Math.floor(Date.now() / 1000),
                updatedAt: Math.floor(Date.now() / 1000),
              },
            })
          );

          const contactPhones = currentContact.phones.map(
            ({ normalized_phone }) => normalized_phone
          );
          dispatch(hideCallsBatch(contactPhones)).unwrap();
          onClose();
        }
      },
      enqueueSnackbar,
    });
  };

  const handleClick = (dest: string) => {
    moveItem(dest);

    if (onClose) {
      onClose();
    }
  };

  const handleClickProposal = () => {
    if (!proposalSelected) {
      onSendProposal();
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorReference="anchorEl"
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
      classes={{ paper: styles.MenuPaper }}
    >
      {board.columns.map(({ id, title, color }) => {
        return (
          <MoveToItem
            eventType={'Move_to'}
            key={id}
            action={action}
            onClick={() => handleClick(id)}
            title={title}
            color={color}
            isSelected={id === columnUuid && !proposalSelected && !contact?.is_archived}
          />
        );
      })}
      <MoveToItem
        eventType={'Move_to'}
        color="#ff6d39"
        action={action}
        title={t('proposals:sectionName')}
        onClick={handleClickProposal}
        isSelected={proposalSelected && !contact?.is_archived}
      />
      <ArchiveToast
        action={action}
        itemUuid={itemUuid}
        columnUuid={columnUuid}
        contactUuid={contactUuid}
        onClose={onClose}
        callContact={contact}
      />
    </Menu>
  );
};
export default MoveToModal;
