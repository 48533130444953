import React from 'react';

import styles from './styles.module.scss';
import DirectedText from '../../../../localization/ui/DirectedText';
import clsx from 'clsx';

type Props = {
  heading: string;
  subHeading?: string;
  noBorder?: boolean;
};

const PageHeader: React.FC<Props> = ({ heading, subHeading, children, noBorder = false }) => {
  return (
    <div className={clsx(styles.heading, { [styles.NoBorder]: noBorder })}>
      <h1>
        <DirectedText>{heading}</DirectedText>
      </h1>
      {children}
      {subHeading && <p>{subHeading}</p>}
    </div>
  );
};

export default PageHeader;
