import React from 'react';
import clsx from 'clsx';

import { IButtonPrimaryProps } from '../Buttons.types';
import BaseButton from '../BaseButton';
import styles from './styles.module.scss';
import PlusIcon from '../../../../../public/images/icons/plus-rounded-icon.svg';

const ButtonPrimary: React.FC<IButtonPrimaryProps> = (props) => {
  const { className, text, icon, onClick, variant } = props;
  let resultIcon: React.ReactNode;
  let additionalClassName: string;

  switch (variant) {
    case 'add': {
      resultIcon = <PlusIcon />;
      additionalClassName = styles.AddButton;
      break;
    }

    case 'rounded': {
      additionalClassName = styles.RoundedButton;
      break;
    }

    default: {
      resultIcon = icon;
      break;
    }
  }

  return (
    <BaseButton className={clsx(styles.Button, className, additionalClassName)} onClick={onClick}>
      {resultIcon}
      <span className={styles.Text}>{text}</span>
    </BaseButton>
  );
};

export default ButtonPrimary;
