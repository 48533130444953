import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useSnackbar } from 'notistack';

import { useAppDispatch, useAppStore } from '../../../../app/hooks';
import MoveToItem from './MoveToItem';
import { useAppSelector } from 'src/app/hooks';
import { makeSelectContactByUuid, patchContactOptimistic } from 'slices/contactsSlice';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import styles from '../styles.module.scss';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import { actions } from '../../../../slices/boardSlice/slice';
import {
  actions as proposalsActions,
  makeSelectProposalsByContact,
} from '../../../../slices/proposalsSlice';

import { createBoardContactItem } from 'src/services/data/modelsEntitiesFactory';
import Contact from 'types/contact';
import { hideCallsBatch } from 'slices/callsSlice';
import { trackEvent } from 'src/analytics/amplitude';

interface IProps {
  contactUuid: string;
  action: string;
  columnUuid?: string;
  itemUuid?: string;
  onClose: () => void;
  callContact?: Contact;
  className?: string;
}

const ArchiveToast: React.FC<IProps> = ({
  contactUuid,
  onClose,
  columnUuid,
  itemUuid,
  callContact,
  action,
  className,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const selectContactByUuid = useMemo(makeSelectContactByUuid, []);
  const relatedContact = useAppSelector((state) =>
    selectContactByUuid(state, contactUuid)
  ) as Contact;
  const resultRelatedContact = callContact || relatedContact;
  const isArchived = resultRelatedContact?.is_archived;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onClick = async () => {
    withErrorSnackbar({
      onTry: async () => {
        const phonesCurrentContactArray = relatedContact.phones.map(
          ({ normalized_phone }) => normalized_phone
        );
        if (!isArchived) {
          const selectProposalsByContact = makeSelectProposalsByContact();
          const proposalIds = selectProposalsByContact(store.getState(), resultRelatedContact.uuid)
            .filter(({ visible }) => visible)
            .map(({ uuid }) => uuid);

          if (proposalIds.length > 0) {
            dispatch(proposalsActions.hideProposals(proposalIds));
          }

          if (columnUuid || (columnUuid && !resultRelatedContact)) {
            dispatch(actions.deleteItems({ columnUuid, itemsUuids: [itemUuid] }));

            if (!resultRelatedContact) {
              throw new Error();
            }
          }
          dispatch(hideCallsBatch(phonesCurrentContactArray)).unwrap();
          await dispatch(
            patchContactOptimistic({
              contactId: resultRelatedContact.uuid,
              data: {
                is_archived: true,
                updated_at: Math.floor(Date.now() / 1000),
                workspace_id: resultRelatedContact.workspace_id,
              },
            })
          ).unwrap();
          trackEvent('Archive', { action: 'Move to action' });

          enqueueSnackbar({
            message: t('tags.person_archived_snackbar', { name: resultRelatedContact.name }),
            variant: MessageType.Success,
            icon: <ArchiveIcon />,
            className: styles.ArchiveSnackbar,
            closeContent: (
              <button
                onClick={() =>
                  withErrorSnackbar({
                    onTry: async () => {
                      if (columnUuid) {
                        dispatch(
                          actions.addItem({
                            columnUuid,
                            item: createBoardContactItem(contactUuid, columnUuid),
                          })
                        );
                      }

                      await dispatch(
                        patchContactOptimistic({
                          contactId: resultRelatedContact.uuid,
                          data: {
                            is_archived: false,
                            updated_at: Math.floor(Date.now() / 1000),
                            workspace_id: resultRelatedContact.workspace_id,
                          },
                        })
                      ).unwrap();
                      closeSnackbar();
                      onClose();
                    },
                    enqueueSnackbar,
                  })
                }
                className={styles.ArchiveSnackbarUndo}
              >
                {t('tags.person_archived_undo_snackbar')}
              </button>
            ),
          });
        }
        onClose();
      },
      enqueueSnackbar,
    });
  };

  return (
    <MoveToItem
      action={action}
      eventType={'Archive'}
      className={className}
      onClick={onClick}
      isSelected={isArchived}
      color={'rgb(0, 0, 0)'}
      title={t('tags.archive')}
    />
  );
};

export default ArchiveToast;
