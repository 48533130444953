import React from 'react';
import { useTranslation } from 'next-i18next';
import BaseButton from 'components/UI/Buttons/BaseButton';
import styles from './styles.module.scss';
import { useAppSelector } from '../../../../../app/hooks';
import { selectIsAdminOrOwner } from 'slices/workspacesSlice';
import { useRouter } from 'next/router';
import { appRoutes } from '../../../../../utils/constants';

const ManageFieldsButton: React.FC = () => {
  const { t } = useTranslation('customFields');
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const router = useRouter();

  if (!isAdmin) return null;

  const onClick = () => {
    router.push(appRoutes.FIELDS_MANAGEMENT);
  };

  return (
    <BaseButton onClick={onClick} className={styles.Button} variant={'text'}>
      {t('manage_fields_button')}
    </BaseButton>
  );
};

export default ManageFieldsButton;
